/** @jsx jsx */
import React from "react"
import Layout from "../components/Layout"
import { jsx, Grid, Box, Text } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Banner from "../components/Banner"
import ContentSection from "../components/ContentSection"
import SEO from "../components/seo"
import { Slide, Zoom, Fade } from "react-awesome-reveal"

interface EthosSubHeadingProps {
  children: React.ReactNode
}

const EthosSubHeading = (props: EthosSubHeadingProps) => {
  return (
    <Slide triggerOnce={true} direction="right" duration={500}>
      <Text variant="default" mb={2} sx={{ fontWeight: "bold", fontSize: 3 }}>
        {props.children}
      </Text>
    </Slide>
  )
}

interface TeamMemberProps {
  image: any
  name: string
  description: string
}

const TeamMember = (props: TeamMemberProps) => {
  const { image, name, description } = props
  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ maxWidth: "300px", margin: "auto" }}>
        <Img fluid={image} alt={name} />
      </Box>
      <Zoom duration={500} triggerOnce>
        <Text
          variant="default"
          sx={{ fontWeight: "bold" }}
          color="textHighlight"
          mt={3}
        >
          {name}
        </Text>
      </Zoom>
      <Fade triggerOnce={true} duration={500}>
        <Text variant="secondary" mt={2} px={5}>
          {description}
        </Text>
      </Fade>
    </Box>
  )
}

export const teamMemberImage = graphql`
  fragment teamMemberImage on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    ethosImage: file(relativePath: { eq: "ethos.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    desktopBanner: file(relativePath: { eq: "ourTeamDesktopBanner.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileBanner: file(relativePath: { eq: "ourTeamMobileBanner.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    babitaImage: file(relativePath: { eq: "babita.png" }) {
      ...teamMemberImage
    }
    saumyaImage: file(relativePath: { eq: "saumya.png" }) {
      ...teamMemberImage
    }
    alokImage: file(relativePath: { eq: "alok.png" }) {
      ...teamMemberImage
    }
    prabhatImage: file(relativePath: { eq: "prabhat.png" }) {
      ...teamMemberImage
    }
  }
`

export default function Team({ data }) {
  return (
    <Layout>
      <SEO title="Our Team" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Our Team"
        alt="our team"
        body={
          <Text variant="default" color="accent">
            We are a team of master nurturers – of green life. A master nurturer
            pays attention to details and through hard work, experimentation and
            research, fosters growth in ways an ordinary gardener doesn’t
            understand. A master works harder for better results.
          </Text>
        }
      />
      <ContentSection
        heading="Our Ethos"
        body={
          <Grid
            columns={[1, null, null, null, "6fr 5fr"]}
            gap={5}
            sx={{ textAlign: "center" }}
          >
            <Box bg="highlight6" p={4}>
              <EthosSubHeading>Therapeutic power of green</EthosSubHeading>
              <Slide triggerOnce={true} direction="left" duration={500}>
                <Text variant="default" color="accent2" mb={4}>
                  The maximum number of variations in any palette of colours
                  that nature has gifted is green. Green signifies hope,
                  rejuvenation and immortality, wrapped in the promise of
                  unbound abundance
                </Text>
              </Slide>
              <EthosSubHeading>Responsible use of water</EthosSubHeading>
              <Slide triggerOnce={true} direction="left" duration={500}>
                <Text variant="default" color="accent2" mb={4}>
                  "on the question of water, it’s quite simply a matter of life
                  and death,” Michael Moller, World Water Summit, Geneva, Feb
                  2019.
                </Text>
              </Slide>
              <EthosSubHeading>Enabling Communities</EthosSubHeading>
              <Slide triggerOnce={true} direction="left" duration={500}>
                <Text variant="default" color="accent2" mb={4}>
                  We pride ourselves in skilling women, disabled people and
                  workforce from local community.
                </Text>
              </Slide>
              <EthosSubHeading>
                Promoting native flora and fauna
              </EthosSubHeading>
              <Slide triggerOnce={true} direction="left" duration={500}>
                <Text variant="default" color="accent2">
                  Our solutions are based on the following parameters.
                  <div>1. Local weather & terrra conditions</div>
                  <div>2. Low maintenance</div>
                  <div>
                    3. Bringing back native trees, plants and non-invasive
                    varieties
                  </div>
                </Text>
              </Slide>
            </Box>
            <Box sx={{ order: ["-1", "0"] }}>
              <Img
                sx={{ height: "100%" }}
                fluid={data.ethosImage.childImageSharp.fluid}
                alt="ethos"
              />
            </Box>
          </Grid>
        }
      />
      <ContentSection
        heading="Our Team"
        body={
          <Grid columns={[1, null, null, 2]} gap={5} mb={4}>
            <TeamMember
              name="Babita Sinha, Co-Founder, YGC Greenscapes"
              description="Babita turned into an ecopreneur out of a strong desire to bring greenery ‘responsibly’ within the indiscriminately expanding concrete jungles. Her art lies in the delicate replication of nature in terrariums, closed spaces and in enlivening the interiors bereft of natural greens. She is an MBA by education."
              image={data.babitaImage.childImageSharp.fluid}
            ></TeamMember>
            <TeamMember
              name="Saumya Lakshman Tiwary, Co-Founder, YGC Greenscapes"
              description="Saumya decided to pursue her passion for greenery in midst of which she had spent her formative life. Her art lies in visualizing spaces – both big & small - and converting them into personal or community spaces embellished with greenery, calmness and serenity. She is an MBA by education."
              image={data.saumyaImage.childImageSharp.fluid}
            ></TeamMember>
            <TeamMember
              name="Alok K Sinha, Chairman, YGC GreenScapes."
              description="Brings over 3 decades of rich experience across global corporations and is helping the company to become pan-India. He is an entrepreneur, Board Member of many organisations and Author of ‘Achieving Successful Business Outcomes’, T&F NY & London, India."
              image={data.alokImage.childImageSharp.fluid}
            ></TeamMember>
            <TeamMember
              name="Prabhat kumar tiwary, Director and Advisor, YGC GreenScapes"
              description="A Stanford Business Graduate, he brings 25 years of professional industry experience in Tech Enterprises and Startups. Currently the CEO and Founder of YourOwnRoom, a leading Residential Prop-Tech Co-living, rental & property management business, he is helping redefine the way millennials live in India. He has held many senior leadership and driven P&Ls upwards of $100 M."
              image={data.prabhatImage.childImageSharp.fluid}
            ></TeamMember>
          </Grid>
        }
      />
    </Layout>
  )
}
