import React from "react"
import { Box, Heading, jsx } from "theme-ui"
import { Fade } from "react-awesome-reveal"

interface ContentSectionProps {
  heading: string
  body: React.ReactNode
}

const ContentSection = (props: ContentSectionProps) => {
  const { heading, body } = props

  return (
    <Box px={[3, 5]} pt={4} my={2}>
      <Fade triggerOnce={true} duration={500}>
        <Heading sx={{ fontSize: 6, textAlign: "center" }} mb={4}>
          {heading}
        </Heading>
      </Fade>
      <Box>{body}</Box>
    </Box>
  )
}

export default ContentSection
